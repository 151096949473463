<template>
    <div>
        <div
            class="firm-panel"
            v-if="isShow"
        >
            <h3 class="g-title">资质证书</h3>
            <ul
                class="honor-items"
                v-if="value.attestation_info && value.attestation_info.credentials"
            >
                <li
                    class="item"
                    v-for="(item,index) in value.attestation_info.credentials"
                    :key="index"
                >
                    <span class="icon">
                        <img :src="$img.firm_honor_pic" />
                    </span>

                    <div class="honor-cont">
                        <h4 class="title">{{item.title}}</h4>
                        <p class="date">上传时间：{{item.time}}</p>
                    </div>
                </li>
            </ul>
            <p
                v-else
                style="width:100%;text-align:center;height:300px;line-height:300px;border:1px solid #e6e6e6;font-size:14px;color:#999;"
            >暂无数据</p>

            <el-dialog
                title="证书详情"
                :visible.sync="honorDialog"
                width="520px"
                center
            >
                <div class="honor-dialog">
                    <h4 class="honor-title">{{honorInfo.title}}</h4>
                    <p class="get-date">获得时间：{{honorInfo.time}}</p>
                    <div class="honor-pic">
                        <img :src="util.reImg(honorInfo.image)" />
                    </div>
                    <p class="upload-date">上传时间：{{honorInfo.ctime}}</p>
                </div>
            </el-dialog>
        </div>
        <div
            class="firm-panel"
            v-else
        >
            <h3 class="g-title">资质证书</h3>
            <div style="width:100%;text-align:center;height:300px;">
                <p style="padding-top:90px;">
                    <i
                        style="font-size:40px;color:#999;"
                        class="el-icon-warning-outline"
                    ></i>
                </p>
                <p style="padding-top: 10px;font-size: 14px;color:#999;">仅入驻的工程极客，或实名认证后的雇主可以查看</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "honorIndex",
    props: ['value'],
    data() {
        return {
            honorDialog: false,
            honorInfo: '',
            isShow: false
        };
    },
    methods: {
        showHonor(item) {
            this.honorDialog = true;
            this.honorInfo = '';
            this.honorInfo = item;
        }
    },
    created() {
        let UserInfos = JSON.parse(localStorage.getItem('vuex'));
        // console.log(UserInfos);
        if (UserInfos.userInfo.attestation && UserInfos.userInfo.attestation.is_pass == 1) {
            this.isShow = true;
        }

    }
};
</script>
<style lang="less">
.honor-items {
    .item {
        .icon {
            img {
                width: 60px;
                height: 60px;
            }
        }
    }
}
</style>






